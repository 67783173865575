.box {
    display: block;
    border-radius: 20px;
    overflow: hidden;
    text-decoration: none;

    &__image-wrapper {
        position: relative;
        height: 0;
        padding-bottom: 54%;
        background-color: $colour__lightgrey;

        img {
            position: absolute;
            top: 0;
left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__heading {
        text-align: center;
        border-radius: 0 0 20px 20px;
        border: 1px solid $colour__midgrey;
        border-top: inherit;
        padding: 2rem;
        background-color: $colour__white;

        h2 {
            margin: 0;
        }
    }
}