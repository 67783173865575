.product-card {
	max-width: 100%;
	text-decoration: none;

	&__image {
		position: relative;
		height: 0;
		padding-bottom: 100%;
		background-color: $colour__blue-500;
		border-radius: 2rem;
		overflow: hidden;

		source,
		img {
			width: 100%;
			transition: transform 0.25s ease-out;
		}

		&--favs {
			img {
				position: absolute;
				inset: 0;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				object-fit: contain;
				max-height: 90%;
			}
		}
	}

	&__data {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__details {
		font-size: 1.2rem;

		h3 {
			font-size: 1.5rem;
			margin: .5rem 0;

			@include breakpoint('medium') {
				font-size: 2rem;
			}
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 3rem;
		margin: 0 1.5rem;

		@include breakpoint('xsmall') {
			grid-template-columns: 1fr 1fr;
		}

		@include breakpoint('small') {
			grid-template-columns: 1fr 1fr;
		}

		@include breakpoint('medium') {
			grid-template-columns: 1fr 1fr 1fr;
		}

		@include breakpoint('large') {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}

		@include breakpoint('xlarge') {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		}
	}

	&__options {
		li {
			display: flex;
			padding: 0.25rem 0;

			&:nth-of-type(even) {
				background-color: $colour__lightgrey;
			}
		}
	}

	&__options-heading {
		text-align: right;
		justify-content: flex-end;
		padding: .5rem;
	}

	&__options-cell {
		flex: 1;
		margin: 0 .5rem;

		&--price {
			flex: 0 0 auto;
		}
	}

	&__power {
		font-weight: bold;
	}

	&__finish-thumb {
		width: 60px;
	}
}

.product-card:hover {
	.product-card__image {
		img {
			transform: scale(110%);
		}

		&--favs {
			img {
				transform: translate(-50%,-50%);
			}
		}
	}
}
