.range-products {
	&__row {
		margin-bottom: 2rem;

		&--flex {
			@include row();

			margin: 0 -1rem -2rem;
		}
	}

	&__col {
		@include col(12, $gap: 2rem);

		&--one {
			@include breakpoint('medium') {
				@include col(2.4, $gap: 2rem);

				flex: 0 0 286px;
				max-width: inherit;
			}
		}

		&--four {
			@include breakpoint('medium') {
				@include col(9.6, $gap: 2rem);

				flex: 1;
				max-width: inherit;
			}
		}
	}

	&__prods {
		@include row($gap: 0);

		margin: 0 -1rem -2rem;
	}

	&__card {
		@include col(12, $gap: 2rem);

		flex: 1 1 278px;
	}

	&__spacer {
		flex: 1 1 278px;
	}

	&__details,
	&__calc {
		padding: 1.5rem 2rem 2rem;
		background-color: $colour__lightgrey;

		&--additional {
			margin: 2rem 0 0;
		}
	}

	&__calc {
		display: block;
		margin-top: 2rem;
		text-decoration: none;

		&:hover {
			background-color: darken($colour__lightgrey, 5%);
		}
	}

	&__details-inner,
	&__calc-inner {
		margin: 0 0 -1.5rem;

		ul {
			padding-inline-start: 1.8rem;
		}
	}
}
