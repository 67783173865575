.full-bleed {
    position: relative;
    padding: 0;

    &__container {
        display: flex;
        padding: 7rem 2rem;
        max-width: 1440px;
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;

        &--center {
            text-align: center;
            justify-content: center;
            align-items: center;
        }

        &--top-center {
            text-align: center;
            justify-content: center;
            align-items: flex-start;
        }

        &--bottom-center {
            text-align: center;
            justify-content: center;
            align-items: flex-end;
        }

        &--left {
            align-items: center;
        }

        &--top-left {
            justify-content: flex-start;
            align-items: flex-start;
        }

        &--bottom-left {
            align-items: flex-end;
        }

        &--right {
            text-align: right;
            justify-content: flex-end;
        }

        &--top-right {
            text-align: right;
            align-items: flex-start;
            justify-content: flex-end;
        }

        &--bottom-right {
            text-align: right;
            align-items: flex-end;
            justify-content: flex-end;
        }
    }

    &__content-wrap {
        max-width: 60ch;
        background: rgba($colour__white, .7);
        padding: 2rem 3rem 3rem;
        border-radius: 2rem;
    }

    &__media {
        height: 0;
        position: relative;
        padding-bottom: 60rem;

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &:nth-of-type(1) {
        + section {
            padding-top: 6rem;
    
            @include breakpoint('medium') {
                padding-top: 10rem;
            }
        }
    }
}