.header {
	padding: 3rem 0;
	margin: 0 0 1.5rem;

	&__row {
		@include row($align: flex-end);
        
        &--top {
            position: relative;
            justify-content: space-between;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
                width: calc(100% - 3rem);
                height: 1px;
                background-color: $colour__secondary--400;
            }
        }
	}

	&__col {
		&--narrow {
			@include col(4);
            flex: 0 0 125px;
		}

		&--wide {
			@include col(8);

			@include breakpoint('large') {
				@include col(10);
			}
		}

		&--nav {
			display: flex;
			align-self: stretch;
			flex-flow: column;
			justify-content: center;

			@include breakpoint('large') {
				justify-content: space-between;
			}
		}
	}
}