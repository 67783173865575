.promo-banner {
	display: block;
	padding: 0.5rem 2rem;
	text-decoration: none;

	&--sticky {
		position: fixed;
		left: 0;
		right: 0;
		top: 0rem;
		z-index: 1;
	}

	&__copy {
		text-align: center;
		text-decoration: none;

		p {
			margin: 0;
		}
	}
}