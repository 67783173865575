.nav {
	display: flex;
	list-style: none;
	padding: 0;
	margin: 0;

	&--main {
		align-items: flex-start;
		flex-flow: column;

		@include breakpoint('large') {
			flex-flow: row;
			align-items: center;
			justify-content: flex-start;
		}
	}

	&--sub {
		list-style: none;
		padding: 0;
		margin: 0;

		&.nav--level-3 {
			margin: 0 0 3rem;
		}
	}

	&--end {
		flex-flow: column;
		align-items: center;

		@include breakpoint('small') {
			flex-flow: row;
			justify-content: center;
		}

		@include breakpoint('medium') {
			justify-content: flex-end;
		}
	}

	&__item {
		margin: 0 1.5rem 1.5rem;

		@include breakpoint('large') {
			margin-bottom: 0;
		}
	}

	&__link {
		padding-bottom: 1px;
		position: relative;
		text-decoration: none;
		cursor: pointer;
		color: $colour__white;

		@include breakpoint('large') {
			color: inherit;
		}

		&::after {
			content: '';
			position: absolute;
			height: 1px;
			width: 100%;
			left: 0;
			bottom: 0;
			opacity: 0;
			transform: translateY(4px);
			background: $colour__primary;
			transition: opacity 0.2s ease, transform 0.2s ease;
		}

		&:hover {
			color: $colour__nav-link--hover;
		}

		&:hover::after {
			opacity: 1;
			transform: translateY(1px);
		}


	}

	&__link--image {
		color: #fff;
		cursor: pointer;
		text-decoration: none;
		margin: 0.5em 0;
		display: block;

		span {
			position: relative;
			display: inline-block;

			&::after {
				content: '';
				position: absolute;
				height: 1px;
				width: 100%;
				left: 0;
				bottom: 0;
				opacity: 0;
				transform: translateY(4px);
				background: $colour__primary;
				transition: opacity 0.2s ease, transform 0.2s ease;
			}
		}

		&:hover {
			color: $colour__nav-link--hover;

			span::after {
				opacity: 1;
				transform: translateY(1px);
			}
		}
	}

	&--level-2 {
		@include breakpoint('large') {
			display: none;
			font-weight: bold;
			max-width: 1400px;
			padding: 3rem 0 150px;
			width: 100%;
			margin: 0 auto;
		}

		.nav__item {
			margin: 1rem 0;

			&--bold .nav__link,
			&--bold .nav__link--passive {
				font-weight: 800;
			}

			&--bold .nav--level-3 .nav__link,
			&--bold .nav--level-3 .nav__link--passive {
				font-weight: inherit;
			}
		}

		.nav__link {
			color: $colour__nav-link;

			&:hover {
				color: $colour__nav-link--hover;
			}

			&:hover::after {
				opacity: 1;
				transform: translateY(1px);
			}
		}

		.nav__link--passive {
			// display: none;

			@include breakpoint('large') {
				display: initial;
				color: $colour__nav-link;

				&:hover {
					color: $colour__nav-link;
					cursor: default;
				}
			}
		}
	}

	&--level-3 {
		//display: none;
		font-weight: normal;

		.nav__item {
			margin: 1rem 0;
		}

		.nav__link--passive {
			display: none;

			@include breakpoint('medium') {
				display: initial;
				color: $colour__nav-link;

				&:hover {
					color: $colour__nav-link;
					cursor: default;
				}
			}
		}

		& .nav__link {
			display: inline-block;
			font-weight: 400;
		}
	}

	&--level-1 li {
		.nav__link--passive {
			cursor: default;
			color: $colour__white;

			@include breakpoint('large') {
				color: inherit;
			}
		}

		&:hover {
			.nav--level-2 {
				display: block;

				.nav__link--passive {
					color: $colour__white;
				}
			}
		}

		@include breakpoint('large') {
			&:hover {
				.nav--level-2 {
					display: flex;
					flex-direction: row;
					gap: 80px;
				}

				.nav__subnav-container {
					display: block;
				}
			}
		}
	}

	&__container {

		@include breakpoint('large') {
			position: relative;
			margin-top: -54px;
		}
	}

	&__subnav-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 10px;
		background: black;
		z-index: 1;
		transition: all 0.5s ease;
		transform: translateX(-101%);
		padding: 2rem 2rem 4rem 2rem;
		min-height: 100vh;

		&--mobile-active {
			transform: translateX(0%);
		}

		@include breakpoint('large') {
			display: none;
			position: absolute;
			top: 100%;
			left: 0;
			background: transparent;
			width: 100%;
			padding: 0;
			padding-top: 29px;
			z-index: 98;
			transform: none;
		}
	}

	&__subnav-wrapper {

		@include breakpoint('large') {
			background: $colour__nav-bg;
			padding: 0 2rem;
			min-height: 400px;
		}
	}

	&__subnav-flex {
		margin-left: 2rem;

		@include breakpoint('large') {
			display: flex;
			max-width: 1400px;
			margin: 0 auto;
		}
	}

	&__image-links {
		//margin-left: 2rem;

		img {
			display: none;
		}

		@include breakpoint('large') {
			display: flex;
			flex-direction: row;
			gap: 30px;
			max-width: 50%;
			height: 100%;
			padding-top: 5rem;

			img {
				display: block;
				width: 150px;
				border-radius: 20px;
				border: 2px solid transparent;
			}
		}

		a {
			&:hover {
				img {
					border-color: $colour__nav-link--hover;
				}
			}
		}
	}

	&__wrapper {
		position: absolute;
		left: 0;
		top: 122px;
		height: calc(100vh - 120px);
		bottom: 0;
		width: 100vw;
		max-width: 450px;
		transform: translateX(-113%);
		z-index: 50;
		transition: transform $transition;
		//text-align: right;
		padding: 2rem 2rem 4rem 2rem;
		font-size: 1.75rem;
		background: $colour__nav-bg;
		color: $colour__white;
		overflow: scroll;

		@include breakpoint('large') {
			position: initial;
			height: inherit;
			width: inherit;
			top: inherit;
			left: inherit;
			text-align: inherit;
			transform: inherit;
			font-size: inherit;
			max-width: 1440px;
			padding: 0 2rem;
			margin: 0 auto;
			background: inherit;
			color: inherit;
			overflow: hidden;
		}

		&--active {
			transform: translateX(0);

			@include breakpoint('large') {
				transform: inherit;
			}
		}
	}

	&__item-wrapper {
		display: flex;
		align-items: center;

		@include breakpoint('large') {
			display: block;
		}
	}

	&__subnav-open {
		background: none;
		border: none;
		color: #fff;
		height: 22px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		@include breakpoint('large') {
			display: none;
		}

		svg {
			fill: $colour__white;
			transform: scale(0.5);
			height: 24px;
			width: 24px;
		}
	}

	&__subnav-close {
		background: none;
		border: none;
		color: $colour__white;
		display: flex;
		align-items: center;
		padding: 0;

		@include breakpoint('large') {
			display: none;
		}

		svg {
			fill: $colour__white;
			transform: scale(-0.5);
			height: 24px;
			width: 24px;
		}
	}
}

body {
	&.nav-active {
		position: fixed;
		overflow: hidden;
	}
}
