html {
	color: $colour__body-text;
	font-family: $type__body-font-family;
	font-size: $type__base-font-size;
	line-height: $type__base-line-height;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
}

body {
	font-size: 1.6rem;
}

a,
.js-link {
	color: $colour__body-text;

	&:hover {
		text-decoration: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
	margin: 0 0 math.div($gutter, 2);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $type__heading-font-family;
	font-weight: 700;
}

h1,
.h1 {
	font-size: 28px;
	@include resp-font-size(28, 30, 32, 34);
}

h2,
.h2 {
	font-size: 24px;
	@include resp-font-size(24, 28, 30);
}

h3,
.h3 {
	font-size: 20px;
	@include resp-font-size(20, 22, 24);
}

h4,
.h4 {
	font-size: 18px;
	@include resp-font-size(18, 20);
}

h5,
.h5 {
	font-size: 16px;
	@include resp-font-size(16, 18);
}

h6,
.h6 {
	font-size: 14px;
	@include resp-font-size(14);
}

.heading-small {
	font-size: 1.75rem;
}

.copy-small p {
	font-size: 1.2rem;
}