.footer {
	padding: 3rem 0;

	&__row {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;
		margin-bottom: 2rem;

		@include breakpoint('medium') {
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 2.5rem;
		}
	}

	&__col--nav {
		.nav {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;

			@include breakpoint('medium') {
				justify-content: flex-end;
			}
		}

		.nav__link {
			color: $colour__black;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	/*
	&__row {
		@include row($align: center);

		margin: 0 -1.5rem;
		justify-content: center;

		@include breakpoint('medium') {
			justify-content: space-between;
		}
	}
	&__col {
		@include col(12);

		@include breakpoint('medium') {
			@include col(6);
		}


		&--social {
				flex: 0;
		}

		&--nav {

			@include breakpoint('medium') {
				flex: 1;
				max-width: 100%;
			}
		}

	}
	*/
}

.footer-copyright {
	text-align: center;

	@include breakpoint('medium') {
		text-align: left;
	}
}
