.txt {
	&--center {
		text-align: center;
	}

	&--left {
		text-align: left;
	}

	&--right {
		text-align: right;
	}
}

.list-reset {
	margin: 0;
	padding: 0;
	list-style: none;
}

.select-opt {
	margin-left: 1rem;
}

.hide-for-mobile {
	display: none;

	@include breakpoint('medium') {
		display: initial;
	}
}

.hide-for-desktop {
	display: initial;

	@include breakpoint('medium') {
		display: none;
	}
}

.show-for-mobile {
	display: intial;

	@include breakpoint('medium') {
		display: none;
	}
}

.show-for-desktop {
	display: none;

	@include breakpoint('medium') {
		display: initial;
	}
}
