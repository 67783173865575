.outlet-variants {
	overflow-x: scroll;

	&__table {
		min-width: 1024px;
		width: 100%;
	}

	&__table-heading {
		th {
			background: #eee;
			padding: 1rem;
		}
	}

	&__variant-row{
		text-align: center;
		padding: 1rem;

		td {
			padding: 1rem;
		}
	}
}