.cards {
	text-align: center;

	&__row {
		@include row();
		justify-content: center;

		&--title {
			@include row($gap: 0, $justify: center);

			margin-bottom: 2rem;
		}
	}

	&__col {
		@include col(12);
		@include breakpoint('medium') {
			@include col(4);
		}
	}
}

.card {
	display: block;
	text-decoration: none;

	&__image {
		display: block;
		position: relative;
		height: 0;
		padding-bottom: 100%;
		border-radius: 2rem;
		overflow: hidden;
		margin-bottom: 1rem;

		img {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	&__text {
		margin: 0 auto;
		max-width: 45ch;

		ul {
			padding-left: 1.65rem;
		}
	}
}
