@font-face {
	font-family: 'Lato';
	src: url('/fonts/lato-regular.woff2') format('woff2'),
		url('/fonts/lato-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lato Bold';
	src: url('/fonts/lato-bold.woff2') format('woff2'),
		url('/fonts/lato-bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}