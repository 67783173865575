*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	// overflow-x: hidden;
}

/*
Forces a sticky footer
*/

body {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	accent-color: $colour__primary;
}

section {
	padding: 3rem 0;

	@include breakpoint('medium') {
		padding: 5rem 0;
	}

	&.top-lrg {
		padding-top: 6rem;

		@include breakpoint('medium') {
			padding-top: 10rem;
		}
	}


	&.bot-lrg {
		padding-bottom: 6rem;

		@include breakpoint('medium') {
			padding-bottom: 10rem;
		}
	}

	&.top-zero {
		padding-top: 0;
	}

	&.bottom-zero {
		padding-bottom: 0;
	}
}

.skip-main {
	left: -999px;
	position: absolute;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
	z-index: -999;

	&:focus,
	&:active {
		position: static;
		left: 0;
		right: 0;
		top: 0;
		width: 100%;
		height: auto;
		overflow: auto;
		background: #fff;
		padding: 5px;
		text-align: center;
		z-index: 999;
	}
}

.container {
	margin: 0 auto;
	padding: 0 2rem;
	max-width:  1440px;

	&--text {
		max-width: 75ch;
	}

	&--950 {
		max-width: 950px;
	}
}

.site-wrap {
	margin-bottom: 5rem;

	&--account {
		display: flex;
		padding-top: 3rem;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
		gap: 1em;

		@include breakpoint('large') {
			flex-wrap: nowrap;
		}
	}

	&__item {
		width: 100%;

		&.account-nav {
			width: 100%;
		}

		@include breakpoint('large') {
			width: calc(100% - calc(200px - 0.5em));

			&.account-nav {
				width: 220px;
			}
		}
	}
}
