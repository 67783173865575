.gallery {
    &__row {
        @include row($gap: 0);

        &--tabs {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        &--gallery {
            display: block;
        }

        .button--primary {
            display: none;
        }
    }

    &__intro {
        text-align: center;
        max-width: 70ch;
        margin-top: 4rem;
    }

    &__slider {
        margin-top: 3rem;
    }

    &__slider-wrapper {
        display: block;
        flex: 1;
    }

    .splide__pagination {
        display: none;

        @media screen and (min-width: 640px) {
            display: inline-flex;
        }
    }
}