.range-gallery {
	margin-bottom: 2rem;

	&__row {
		@include row($gap: 0);

		height: 100%;
		flex-flow: column;
		@include breakpoint('medium') {
			flex-flow: row;
			margin: 0 -.75rem;
		}
	}

	&__col {
		text-align: center;
		color: $colour__white;
		flex: 1;

		&--image {
			@include breakpoint('medium') {
				@include col(8, $gap: 15px);

				margin: 0;
			}
		}

		&--video {
			iframe {
				height: 384px;
			}

			@include breakpoint('medium') {
				@include col(8, $gap: 15px);

				margin: 0;

				iframe {
					height: 100%;
				}
			}
		}

		&--text {
			@include breakpoint('medium') {
				display: flex;
				@include col(4, $gap: 15px);

				margin: 0;
			}
		}
	}

	&__text {
		display: flex;
		flex-flow: column;
		flex: 0 0 100%;
		justify-content: flex-end;
		padding: 1.8rem;
	}

	&__desc {
		display: flex;
		justify-content: center;
		flex-flow: column;
		flex: 1;
		font-size: 1.8rem;
	}

	&__cap {
		display: none;

		@include breakpoint('medium') {
			display: block;
		}
	}
}
