@mixin row($gap: $gutter, $align: initial, $justify: initial, $flush: false) {
	display: flex;
	flex-wrap: wrap;
	align-items: $align;
	justify-content: $justify;

	@if $gap != 0 {
		@if $flush {
			margin: 0 -#{math.div($gap, 2)};
		}
		@else {
			margin: 0 -#{math.div($gap, 2)} -#{$gap};
		}
	}
	@else {
		margin: 0;
	}
}

@mixin col($count: $columns, $gap: $gutter, $align: top, $push: $columns, $direction: left, $exact: false) {
	@if $exact {
		$width: $count;

		flex: 1 1 auto;
		max-width: $width;
	}
	@else if $count != 'auto' {
		$width: percentage(math.div($count, $columns)) !default;

		flex: 0 0 $width;
		max-width: $width;
	}
	@else {
		flex: 1 1 auto;
		max-width: 100%;
	}

	padding: 0 math.div($gap, 2);
	margin: 0 0 $gap;

	@if $push != $columns {
		margin-#{$direction}: percentage($push/$columns);
	}
}
