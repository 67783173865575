select {
    position: relative;
    color: $colour__secondary--100;
    padding: .75rem 3.75rem .75rem .75rem;
    appearance: none;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.754' height='9.291' viewBox='0 0 15.754 9.291'%3E%3Cpath id='Path_45' data-name='Path 45' d='M7490.637,936l7.17,7.17,7.169-7.17' transform='translate(-7489.93 -935.293)' fill='none' stroke='%232b2012' stroke-width='2'/%3E%3C/svg%3E%0A") 94% 15px / 20px 14px no-repeat;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    background-color: $colour__lightgrey;
    padding: 1.25em 1em;
    margin-bottom: 1em;

    &__col,
    &__col-group {
        width: 100%;

        &--bar {
            padding: 0 0 1em 0;
            border-bottom: 3px solid white;
            margin-bottom: 1em;
        }
    }

    &__col-heading {
        width: 100%;
    }

    &__col-group {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5em;

        .filters__col-heading {
            margin-bottom: 0;
        }
    }

    @include breakpoint('large') {
        flex-wrap: nowrap;
        gap: 1em;

        &__col,
        &__col-group {
            width: initial;

            &--bar {
                padding: 0 1em 0 0;
                border-right: 3px solid white;
                border-bottom: 0;
                margin-bottom: unset;
            }
        }

        &__col-group {
            gap: 1em;
        }
    }
}

.filter {
    width: 100%;
    margin-bottom: 0.5em;

    &__label {
        display: block;
    }

    &__input,
    &__select {
        width: 100%;
    }

    &__select {
        background: unset;
        background-color: $colour__nav-link;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.754' height='9.291'%3E%3Cpath data-name='Path 45' d='M.707.707l7.17 7.17 7.169-7.17' fill='none' stroke='%232b2012' stroke-width='2'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 98% 50%;
    }

    &--range {
        .filter__input {
            margin-bottom: 0.25em;
        }
    }

    .button {
        width: 100%;
    }

    @include breakpoint('large') {
        width: fit-content;

        .button {
            width: fit-content;
        }
    }
}