.text-block {

	&__wrapper {

		&--left {
			text-align: left;
		}

		&--center {
			text-align: center;
		}

		&--right {
			text-align: right;
		}
	}

	&__body {
		&--intro {
			font-size: 1.8rem;
		}
	}

	&__form {
		text-align: left;
	}

	.container {
		max-width: 95ch;
	}

	.button {
		margin-top: 1rem;
	}
}
