@media print {

	body > .container {
		width: 180mm;
		max-width: 180mm;
		margin: 0 auto;
	}


	header {
		nav,
		.megamenu,
		.nav-toggle {
			display: none;
		}

		.logo {
			width: 237px;
			height: 30px;
			margin: 0 0 15px;

			img {
				&.print-hide {
					display: none;
				}

				&.hidden {
					display: block;
				}
			}
		}
	}



	.breadcrumbs,
	.builder-controls,
	.btn-group {
		display: none;
	}

	.column {
		width: 100% !important;

		&:first-of-type {
			display: none !important;
		}
	}

	.page {
		&-introduction {
			margin-bottom: 14pt;

				h1 {
					font-family: "Lora-bold", serif;
					font-weight: normal;
				}
		}
	}

	.product-builder {
		width: 120mm;
		height: 120mm;
		margin: 0 auto 10pt;
		padding: 0;
	}

	.product-selections {
		font-size: 9pt;

		.remove {
			display: none;
		}

		&.product-selections td { // hack to get around specificity issue where this was overridden by main styles
			padding-top: 5pt;
			padding-bottom: 5pt;
		}

		tfoot {
			strong {
				text-transform: uppercase;
			}
		}
	}



	footer {
		.social-links,
		.footer-nav,
		.copyright {
			display: none;
		}

		.brand-bar {
			margin: 0 0 20pt;

			&::after {
				content: "Bisque. A division of Zehnder Group UK Ltd";
				margin: 28pt 0 0;
				font-size: 11pt;
			}
		}
	}
}
