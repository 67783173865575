.freeform-form {
	label {
		text-transform: uppercase;
		font-size: 13px;
		letter-spacing: 0.06ex;

		+ input,
		+ textarea,
		+ select {
			margin-top: 0.8rem;
		}
	}

	.freeform-button-submit {
		margin-top: 1.5rem;
		background: #2b2012;
		border: 1px solid #2b2012;
		color: #fff;
		display: inline-block;
		text-decoration: none;
		cursor: pointer;
		padding: 1rem 2rem;
		border-radius: 4rem;
		transition: 0.3s;

		&:hover {
			background: #fff;
			color: #2b2012;
		}
	}
}

.form {
	&__row {
		margin-bottom: 1rem;

		@include breakpoint('medium') {
			display: flex;
			justify-content: space-between;
		}
	}

	&__row--vert {
		@include breakpoint('medium') {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}

	&__error {
		color: $colour__primary;
	}

	&__field {
		width: 100%;

		@include breakpoint('medium') {
			width: calc(50% - 1rem);
		}

		&--fullwidth {
			@include breakpoint('medium') {
				width: 100%;
			}
		}
	}

	&__label {
		font-size: 10px;
		text-transform: uppercase;
		line-height: 1.2;
		margin: 10px 0;
		display: flex;
    gap: 0.5rem;

		.required {
			color: $colour__primary;
			font-size: 1.5rem;
			top: 0;
		}

		&--checkbox {
			align-items: center;
			display: flex;

			input {
				margin-right: .75rem;
			}
		}
	}

	&--freeform {
		padding-top: 5rem;

		input[type="text"],
		input[type="email"],
		input[type="tel"],
		input[type="datetime-local"],
		textarea,
		select {
			font-size: 1.25rem;
			width: 100%;
		}

		textarea {
			min-height: 10rem;
		}
	}

	&__checkbox {
		vertical-align: top;
	}

	&__terms-button {
		background: none;
		border: none;
		color: inherit;
		text-decoration: inherit;
		text-transform: inherit;
		padding: 0;
		font-weight: bold;

		&:hover {
			cursor: pointer;
			color: $colour__primary;
		}
	}

	&--account {
		.form__heading {
			font-size: 1.5rem;
		}

		.form__row {
			display: block;
			margin-bottom: 2rem;
			position: relative;

			&--checkbox {
				input[type="checkbox"] {
					position: absolute;
					top: 0;
					left: 0;
					width: unset;
				}

				.form__label {
					padding-left: 2rem;
				}
			}
			
		}

		input {
			width: 100%;
			padding: 0 10px;
		}

		// .button {
		// 	appearance: none;
		// 	background: $colour__black;
		// }
	}

	&__group {
		border: 1px solid #CECECE;
		border-radius: 2rem;
		margin-bottom: 2.5rem;
		padding: 1.5rem 2.5rem;
	}


	&__add,
	&__remove {
		appearance: none;
		border: 0;
		padding: 0;
		display: block;
		background: transparent;
		font-size: 0.95rem;
		letter-spacing: 0.1ex;
		cursor: pointer;
		text-transform: uppercase;
	}

	&__remove {
		color: $colour__primary;
		margin-bottom: 0.5rem;
	}
	
	&__add {
		margin-bottom: 2.5rem;
	}
}

.ff-form {
	&-success {
		padding: 15px;
		border: 1px solid #c6f4d9;
		border-radius: 5px;
		background: #d7f7d7;
		color: #1c7046;

		> p {
			margin: 0;
		}
	}
}



