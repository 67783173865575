@mixin breakpoint($breakpoint, $dir: min) {
	@if map-has-key($breakpoints, $breakpoint) {
		@if $dir == min {
			@media (min-width: map-get($breakpoints, $breakpoint)) {
				@content;
			}
		}
		@else if $dir == max {
			@media (max-width: map-get($breakpoints, $breakpoint)) {
				@content;
			}
		}
	}
	@else {
		@if unitless($breakpoint) {
			$breakpoint: $breakpoint + px;
		}
		@if $dir == min {
			@media (min-width: $breakpoint) {
				@content;
			}
		}
		@else if $dir == max {
			@media (max-width: $breakpoint) {
				@content;
			}
		}
	}
}
