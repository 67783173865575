.product-variant {
    max-width: 100%;
	text-decoration: none;

    &__image {
        position: relative;
        height: 0;
        padding-bottom: 100%;
        background-color: $colour__blue-500;
        border-radius: 2rem;

        img {
            position: absolute;
            inset: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: contain;
            max-height: 90%;
        }

        &--fallback {
            width: 100%;
            border-radius: 2rem;
        }
    }

    &__details {
        font-size: 1.2rem;

        h3 {
            margin: .5rem 0;
        }
    }

    &__options {

        li {
            display: flex;
            padding: 0.25rem 0;
            align-items: center;

            &:nth-of-type(even) {
                background-color: $colour__lightgrey;
            }
        }
    }

    &__options-heading {
        text-align: right;
        justify-content: flex-end;
        padding: .5rem;
    }

    &__options-cell{
        flex: 1;
        margin: 0 .5rem;

        &--price {
            flex: 0 0 auto;
            padding: 0 2px;
        }

        &--stock {
            border: 1px solid;
        }
    }

    &__power {
        font-weight: bold;
    }
}