.blog-entry {

    &__header {
        text-align: center;

        span {
            display: block;
            margin: 1rem 0 3rem 0;
        }
    }

    &__title {
        max-width: 50ch;
        margin: 0 auto;
    }

    &__cat {
        display: block;
        text-decoration: none;
        color: $colour__primary;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 1.4rem;
        margin-top: 1rem;
    }

    &__date {
        text-transform: uppercase;
        font-size: 1.4rem;
    }

    &__intro {
        font-family: Arial, serif;
        text-align: left;
    }

    &__main {

        figure {
            margin: 15px 0;

            figcaption {
                text-align: center;
            }
        }

        img {
            max-width: 100%;
            border-radius: 1rem;
        }

        h2,
        h3 {
            margin-top: 3rem;

            + div.text-image__row {
                margin-top: 2.5rem;
            }
        }

        iframe {
            width: 100%;
            aspect-ratio: 16 / 9;
            border-radius: 1rem;
            margin: 0 0 1.5rem;
        }

        h2:first-child {
            margin-top: 0;
        }

        a.button {
            display: flex;
            max-width: max-content;

            &--center {
                margin: 0 auto 1.5rem;
            }

            &--left {
                margin: 0 auto 1.5rem 0;
            }

            &--right {
                margin: 0 0 1.5rem auto;
            }
        }
    }
}

.blog {
	&__grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 3rem;
		margin: 0 1.5rem;

		@include breakpoint('xsmall') {
			grid-template-columns: 1fr;
		}

		@include breakpoint('small') {
			grid-template-columns: 1fr 1fr;
		}

		@include breakpoint('medium') {
			grid-template-columns: 1fr 1fr 1fr;
		}

		@include breakpoint('large') {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}

		@include breakpoint('xlarge') {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}
}

