.samples {
    &__col {
        @include breakpoint('medium') {
            width: calc(50% - 1rem);
        }
    }

    &__heading {
		font-size: 10px;
		text-transform: uppercase;
		line-height: 1.2;
		margin: 10px 0;
	}

    &__finishes {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

    input[type="text"],
    input[type="email"],
    select {
        font-size: 1.25rem;
        width: 100%;
    }

    select {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.754' height='9.291' viewBox='0 0 15.754 9.291'%3E%3Cpath id='Path_45' data-name='Path 45' d='M7490.637,936l7.17,7.17,7.169-7.17' transform='translate(-7489.93 -935.293)' fill='none' stroke='%232b2012' stroke-width='2'/%3E%3C/svg%3E%0A") 96% 12px/20px 11px no-repeat;
    }
}

.samples-preview {
    height: 0;
    padding-bottom: 100%;
    background-color: #f8f8f8;
    position: relative;
    margin: 0 0 2rem;

    &__image {
        background-repeat: no-repeat;
        background-size: cover;
        height: 0;
        padding-bottom: calc(100% - 120px);
    }

    &__meta {
        min-height: 120px;
        padding: 1rem;
        text-align: center;
    }

    &__default {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        text-align: center;
        width: 100%;
    }
}

.samples-preview-copy {
    padding-top: 10px;
}

.samples-request {
    padding-bottom: 0;

    &__content {
        display: flex;
        flex-direction: column-reverse;

        @include breakpoint('medium') {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

.samples-selected {
    border-top: solid 1px #f8f8f8;
    list-style: none;
    margin: 0 0 2rem;
    padding: 0;

    &__item {
        border-bottom: solid 1px #f8f8f8;
        display: flex;
        align-items: center;
        padding: 5px 0;
        line-height: 1.2;
    }

    &__finish {
        height: 25px;
        margin-right: 1rem;
        width: 25px;
    }

    &__name {
        flex-grow: 1;
    }

    &__remove {
        background: none;
        border: 0;
        cursor: pointer;
        padding: 0;
        margin:0;
        width: 14px;
    }
}
.samples-error-container {
    margin: 0 0 2rem;

    p, li {
        color: $colour__primary;
    }

    p {
        font-weight: 600;
    }
}

.samples-error-list {
    list-style: none;
    margin: 0 0 2rem;
    padding: 0;
}

