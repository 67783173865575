.testimonial {

    &__row {
        display: flex;
        flex-flow: column;
        border: 1px solid $colour__midgrey;
        border-radius: 2rem;
        overflow: hidden;

        @include breakpoint('medium') {
            flex-flow: row;
            overflow: visible;
        }

        &--reverse {
            @include breakpoint('medium') {
                flex-flow: row-reverse;
            }

            .testimonial__col--image {
                @include breakpoint('medium') {
                    border-radius: 2rem 0 0 2rem;
                }
            }
        }

        &--one-col {
            position: relative;
            text-align: center;
            overflow: visible;

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: -29px;
                left: 50%;
                transform: translateX(-50%);
                width: 58px;
                height: 58px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cg id='Group_135' data-name='Group 135' transform='translate(-198 -6996)'%3E%3Ccircle id='Ellipse_25' data-name='Ellipse 25' cx='25' cy='25' r='25' transform='translate(198 6996)' fill='%23fff'/%3E%3Cpath id='iconmonstr-quote-1' d='M14.017,21V13.609c0-5.7,3.731-9.57,8.983-10.609l1,2.151A6.616,6.616,0,0,0,20,11h4V21ZM0,21V13.609C0,7.905,3.748,4.039,9,3l1,2.151A6.615,6.615,0,0,0,6,11H9.983V21Z' transform='translate(211 7009)' fill='%23e30613'/%3E%3C/g%3E%3C/svg%3E%0A") center / contain no-repeat;
            }
        }
    }

    &__col {
        flex: 1;

        :is(figure, blockquote) {
            margin: 0;
        }

        blockquote {
            position: relative;
        }

        figcaption {
            margin-top: 1rem;
            color: $colour__grey--400;
        }

        &--text {
            display: flex;
            align-items: center;
            padding: 4rem;
        }

        &--image {
            overflow: hidden;

            @include breakpoint('medium') {
                border-radius: 0 2rem 2rem 0;
                flex: 0 0 380px;
                margin: -.1rem;
            }
    
        }
    }

    &__image-wrap {
        position: relative;
        height: 0;
        padding-bottom: 100%;

        img {
            position: absolute;
            inset: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__quote {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: -14px;
            height: 14px;
            width: 10px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z' fill='%23e30613'/%3E%3C/svg%3E") center / contain no-repeat;
        }
    }
}