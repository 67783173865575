.account-nav {
	background-color: #F8F8F8;
	padding: 1.25rem 1.5rem 0.5rem;

	&__list {
		margin: 0 0 2.5rem;
		padding: 0;
		list-style: none;
	}

	&__item {
		&:not(&:last-of-type) {
			margin-bottom: 1.2ex;
		}
	}

	&__link {
		text-decoration: none;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			height: 1px;
			width: 100%;
			left: 0;
			bottom: 0;
			opacity: 0;
			transform: translateY(4px);
			background: $colour__primary;
			transition: opacity 0.2s ease, transform 0.2s ease;
		}

		&:hover {
			&::after {
				opacity: 1;
				transform: translateY(1px);
			}
		}

		&--active {
			&::after {
				opacity: 1;
				transform: translateY(1px);
			}
		}
	}
}

