.button {
	display: inline-block;
	text-decoration: none;
	transition: all $transition;
	cursor: pointer;
	padding: 1rem 2rem;
	border-radius: 4rem;

	&:hover {
		transition: all $transition;
	}

	&--primary {
		background: $colour__secondary--400;
		border: 1px solid $colour__secondary--400;
		color: $colour__white;

		&:hover {
			background: none;
			color: $colour__secondary--400;
		}
	}

	&--secondary {
		background: $colour__primary;
		color: #fff;
		border: 1px solid $colour__primary;

		&:hover {
			background: none;
			color: $colour__primary;
		}
	}

	&--search {
		background: transparent;
		border: none;
		padding: 0;
	}
}
