@include keyframes(fade-in) {
	0% {
		opacity: 0;
	}

	90% {
		opacity: 1;
	}
}

@include keyframes(fade-in-up) {
	0% {
		opacity: 0;
		transform: translateY(2rem);
	}

	90% {
		opacity: 1;
		transform: translateY(0);
	}
}

@include keyframes(fade-in-down) {
	0% {
		opacity: 0;
		transform: translateY(-2rem);
	}

	90% {
		opacity: 1;
		transform: translateY(0);
	}
}

@include keyframes(fade-out-up) {
	0% {
		opacity: 1;
		transform: translateY(2rem);
	}

	90% {
		opacity: 0;
		transform: translateY(0);
	}
}

@include keyframes(fade-out-down) {
	0% {
		opacity: 1;
		transform: translateY(2rem);
	}

	90% {
		opacity: 0;
		transform: translateY(0);
	}
}
