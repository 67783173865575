.logo {
    position: relative;
    height: 0;
    padding-bottom: 100%;

    img {
        position: absolute;
        top: 0;
left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}