.social {
	display: flex;
	align-items: center;
	list-style: none;
	padding: 0;
	margin: 0 -1.1rem;

	&__item {
		margin: 0 1.1rem;
	}

	&__link {
		text-decoration: none;
	}

	&__icon {
		width: 28px;
		height: 28px;
		fill: $colour__primary;
		transition: all $transition;

		&:hover {
			fill: $colour__secondary--400;
		}

		&--linkedin {
			border-radius: 10rem;
			border: .5rem solid $colour__primary;

			&:hover {
				border: .5rem solid $colour__secondary--400;
			}
		}
	}
}