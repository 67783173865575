.dealers {
	&__sign-in {
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 100px;
		max-width: 1000px;
		width: 100%;
		margin: 0 auto;

		form {
			//width: 350px;

			button[type='submit'] {
				width: 100%;
				margin: 1rem 0;
			}
		}

		@include breakpoint('medium') {
			grid-template-columns: 1fr 1fr;
		}
	}
}
