.splide {
    
    img {
        width: 100%;
    }

    &__pagination {
        .is-active {
            background-color: $colour__primary;
        }
    }

    &__pagination__page {
        opacity: 1;
        transform: scale(1.4);
        background: $colour__white;
        border: 1px solid $colour__lightgrey;
        margin: 0 .5rem;
    }

    &__img-wrapper {
        position: relative;
        height: 0;

        img {
            position: absolute;
            inset: 0;
            object-fit: contain;
        }
    }

    &__body {
        text-align: center;
        max-width: 50ch;
        margin: 1rem auto 0 auto;
    }
    
    &--gallery {
        .splide__list {
            .splide__slide {
                opacity: 0.5;
                transition: all $transition;
            }
    
            .is-active {
                opacity: 1;
    
                .splide__body {
                    opacity: 1;
                }
            }
        }

        .splide__img-wrapper {
            padding-bottom: 67%;

            img {
                height: 100%;
                width: 100%;
            }
        }

        .splide__body {
            opacity: 0;
            transition: all $transition;
        }

        .splide__pagination {
            bottom: 11rem;

            @include breakpoint('xsmall') {
                bottom: 12rem;
            }
        }

    }

    &--downloads {

        .splide__img-wrapper {
            overflow: hidden;
            padding-bottom: 100%;
            border-radius: 2rem;
            img {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                box-shadow: 5px 5px 10px rgba($colour__black, 0.17);
                height: initial;
                width: initial;
                max-width: 70%;
                max-height: 70%;
            }
        }

        .splide__pagination__page {
            box-shadow: 0 0 3px rgba($colour__black, 0.3);
        }

        .splide__pagination {
            bottom: -3rem;
        }
    }

    &--range-gallery {
        .splide__pagination {
            bottom: -2rem;
        }

        .splide__pagination__page {
            box-shadow: 0px 0px 3px rgb(0 0 0 / 37%);
        }
    }

    &__arrow {
        background: $colour__white;

        &.splide__arrow--prev {
            left: -8px;
        }

        &.splide__arrow--next {
            right: -8px;
        }

        @include breakpoint('small') {
            &.splide__arrow--prev {
                left: 1em;
            }

            &.splide__arrow--next {
                right: 1em;
            }
        }
    }
}