.tabs {
	display: flex;
	flex-direction: column;
	text-align: center;
	overflow: hidden;
	gap: 1rem;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	width: 100%;

	@include breakpoint('medium') {
		flex-wrap: nowrap;
		flex-direction: row;
		gap: 0;
		max-width: 800px;
	}
	//flex-wrap: wrap;

	//border-radius: 3rem;
	//border: 1px solid $colour__secondary--400;

	&__item {
		align-items: center;
		text-decoration: none;
		white-space: nowrap;
		padding: 1rem 2rem;
		width: 100%;
		//border-left: 1px solid $colour__secondary--400;
		transition: background-color color $transition;
		border-radius: 3rem;
		border: 1px solid $colour__secondary--400;
		flex: 1 1 40%;
		flex-wrap: nowrap;


		@include breakpoint('medium') {
			padding: 1rem 4rem;
			width: auto;
			border-radius: 0;
			border-right-width: 0;
			max-width: 25%;

			&:first-child {
				border-top-left-radius: 3rem;
				border-bottom-left-radius: 3rem;
			}

			&:last-child {
				border-top-right-radius: 3rem;
				border-bottom-right-radius: 3rem;
				border-right-width: 1px;
			}
		}

		&--active {
			background: $colour__secondary--400;
			color: $colour__white;
		}

		&:hover {
			background: $colour__secondary--400;
			color: $colour__white;
		}
	}
}
