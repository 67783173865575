.retailer-search {
	display: flex;
	flex-direction: column;
	gap: 2rem;

	&__search-col {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 2rem;
		flex-direction: column;

		form {
			flex: 1;
		}
	}

	&__results-col {
		overflow: hidden;
	}

	&__map-container {
		overflow: hidden;

		.gm-map {
			width: 100%;
			max-width: 100%!important;
		}
	}

	&__label {
		display: block;
	}

	&__input,
	&__select {
		display: block;
		width: 100%;
		margin: 0 0 1rem;
		border: 2px solid #b8b8b8;
		border-radius: 3px;
		outline-color: #e30613;
	}

	&__input {
		padding: 0.75rem;
	}

	&__actions {
		display: flex;
		justify-content: space-between;
	}

	&__search,
	&__reset {
		width: calc(50% - 0.5rem);
		border: 2px solid #b8b8b8;
		border-radius: 3px;
		outline-color: #e30613;
		padding: 0.75rem;
	}

	&__search {
		background: #e30613;
		color: #fff;
		border: rgba(227, 6, 19, 0.3);
	}

	&__reset {
		background: none;
	}

	&__retailer-results {
		margin: 2rem 0 0;
	}

	&__retailer-col {
		width: 100%;
	}

	&__retailer {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #b8b8b8;
		padding: 2rem 0;

		&:first-of-type {
			border-top: 1px solid #b8b8b8;
		}
	}

	&__retailer-card {
		background: #eee;
		padding: 1rem;
		width: 100%;

		a {
			word-wrap: break-word;
		}
	}

	&__retailer-actions {
		display: flex;
		justify-content: flex-end;
	}

	&__retailer-button {
		display: flex;
		padding: 1rem;
		background: #eee;
		border: 2px solid #eee ;
		outline-color: #e30613;
		margin: 0 0 0 0.5rem;

		&:hover,
		&:focus {
			background: #e30613;
			border: 2px solid rgba(227, 6, 19, 0.3);
			outline-color: #e30613;
			cursor: pointer;
		}
	}

	&__icon {
		fill: #fff;
	}

	&__field {
		&--search {
			position: relative;
		}
	}

	&__search-icon {
		position: absolute;
		bottom: 0.5rem;
		right: 1rem;
		width: 2.5rem;
		pointer-events: none;
	}

	@include breakpoint('medium') {
		flex-direction: row;
		gap: 2rem;

		&__retailer-col {
			width: 50%;
		}
	}

	@include breakpoint('large') {
		&__retailer-card {
			width: 75%;
		}
	}
}
