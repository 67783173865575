table {
	&.with-borders {
		margin: 1rem 0;
		border-collapse: collapse;
		width: 100%;

		tr {

		}

		th {
			border: 1px solid $colour__grey--400;
			background: $colour__grey--400;
			color: $colour__body-text;
			text-align: left;
			padding: 1rem;
		}

		td {
			vertical-align: top;
			border: 1px solid $colour__grey--400;
			padding: 1rem;
		}
	}
}

.table {
	width: 100%;
	border-collapse: collapse;
	font-size: 14px;
	color: $colour__darkgrey;

	th,
	td {
		padding: 9px 18px;
	}

	th {
		vertical-align: bottom;
	}

	&-wrap {
		width: 100%;
		overflow-x: scroll;
	}

	&__head,
	&__foot {
		background-color: $colour__lightgrey;
	}

	&__body {
		tr {
			border-top: 1px solid $colour__lightgrey;
		}
	}
}

.pagination {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 1rem;

	svg {
		width: 1rem;
	}
}
