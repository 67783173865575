.icons {
	@include row($gap: 0, $align: center, $flush: true);
	align-items: baseline;

	&__box {
		flex: 0 0 20px;
		margin: 0 0.5rem;
		display: flex;
		align-items: center;
		gap: 1rem;
	}

	&__hamburger {
		@include breakpoint('large') {
			display: none;
		}
	}

	&__icon {
		.header & {
			width: 24px;
		}
	}

	&__tooltip {
		width: 100%;
		max-width: 2rem;
		vertical-align: text-bottom;

		path {
			color: $colour__primary;
			fill: $colour__primary;
		}
	}
}

@media only screen and (max-width: 768px) {
	.header {
		.icons {
			display: grid;
			grid-template-columns: 1fr auto;
			grid-template-rows: 1fr 1fr;
			gap: 1.5rem;
	
			&__box {
				grid-row-start: 1;
			}

			&__hamburger {
				grid-row-start: 2;
			}
		}
	}
}