.img,
.isvg {
	max-width: 100%;
	height: auto;

	&--block {
		display: block;
	}

	&--round {
		border-radius: 50%;
	}
}


.hidden {
	display: none;
}


ul.reset {
	list-style-type: none;
	padding-left: 0;

	&--text {
		strong {
			padding-inline-end: 0.5rem;
		}
	}
}