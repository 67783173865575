.range-filter {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 2rem;
	gap: 3rem;

	@include breakpoint('medium') {
		flex-direction: row;
	}

	&__option {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		
		@include breakpoint('small') {
			width: 80%;
		}

		@include breakpoint('medium') {
			display: block;
			width: auto;
		}
	}

	&__label {
		display: inline-block;
		min-width: 90px;

		@include breakpoint(930) {
			display: inline;
		}
	}

	&__parameter {
		width: 50%;

		@include breakpoint('small') {
			width: 65%;
		}

		@include breakpoint('medium') {
			width: 160px;
		}
	}
}