.listing-cats {

    &__row {
        @include row(0);
       // margin: 0 -1.5rem -3rem;
		margin: 2rem 0 0;

        &--tabs {
            align-items: center;
            flex-direction: column;
            margin-bottom: 5rem;
        }


        &--listing {
            flex-wrap: wrap;
            justify-content: left;
        }

        &--pagination {
            justify-content: center;
            margin-top: 4rem;
            margin-bottom: -1rem;

            a {
                margin: 0 1rem 1rem;
            }
        }

    }

    &__col {
        &--radios {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
        }
    }

	&__filters {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		width: 100%;
		align-items: center;
		margin-bottom: 1.5rem;

		@include breakpoint('medium') {
			flex-direction: row;
			justify-content: space-between;
			padding: 0 1.5rem;
		}
	}

    &__dropdown {
        margin-left: 1rem;
    }
}
