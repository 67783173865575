.logo-grid {

    &__row {
        display: flex;
        flex-flow: row wrap;
        justify-content: center; 
        margin-bottom: -2rem;

        @include breakpoint('medium') {
            margin-bottom: -3rem;
        }


        &--intro {
            margin: 0 auto 2rem;
            max-width: 75ch;
            text-align: center;
        }
    }

    &__col {
        flex: 0 1 100px;
        padding: 0 1rem;
        margin: 0 0 2rem;

        @include breakpoint('medium') {
            flex: 0 1 200px;
            padding: 0 1.5rem;
            margin: 0 0 3rem;
        }
    }
}