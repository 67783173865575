.feature {

    &--overlay {
        @include breakpoint('medium') {
            padding-bottom: 15rem!important;
        }
    }

    &__row {
        @include row($gap: 0);
        overflow: hidden;

        @include breakpoint('medium') {
            border-radius: 0;
            overflow: visible;
        }

        &--flip {
            @include breakpoint('medium') {
                flex-flow: row-reverse;

                .feature__overlay-image {
                    transform: translate(111%, 14%);
                }

                .feature__text {
                    margin-left: 4rem;
                }

                .feature__col--text {
                    border-radius: 0 2rem 2rem 0;
                }
            }
        }

        &--rounded {
            border-radius: 2rem;
        }
    }

    &__col {
        @include col(12, $gap: 0);

        @include breakpoint('medium') {
            @include col(6, $gap: 0);
        }

        &--text {
            display: flex;
            align-items: center;
            padding: 2.5rem;
            overflow: hidden;

            @include breakpoint('medium') {
                padding: 5.5rem;
                border-radius: 2rem 0 0 2rem;
            }
        }

        &--image {
            position: relative;

            @include breakpoint('medium') {
                min-height: 560px;
            }
        }

        &--form {
            padding-right: 5.5rem;
        }
    }

    &__overline {
        font-weight: 800;
    }

    &__text {
        max-width: 50ch;
    }

    &__main-image {
        display: block;
        position: relative;
        height: 0;
        padding-bottom: 82%;

        @include breakpoint('medium') {
            height: 100%;
            padding-bottom: inherit;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__overlay-image {
        display: none;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 0;
        width: max(50%, 15rem);
        padding-bottom: 60%;
        transform: translate(-13%, 14%);

        @include breakpoint('medium') {
            display: block;
        }

        img {
            position: absolute;
            top: 0;
left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}