.ranges {

    &__row {
        @include row($align: flex-start);
    }

    &__col {
        @include col(12);

		@include breakpoint('medium') {
			@include col(6);
		}
    }
}