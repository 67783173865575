.text-image {
    &__row {
        @include row();
        margin: 5rem -15px;

        @include breakpoint('medium') {
            padding: 0 2rem;
            margin-left: calc(50% - 50vw);
            margin-right: calc(50% - 50vw);
		}

        @include breakpoint(1182px) {
            margin-left: -40%;
            margin-right: -40%;
		}

        &--flip {
            flex-flow: row-reverse wrap
        }
    }

    &__col {
        @include col(12);
        margin-bottom: 0;

        @include breakpoint('medium') {
            @include col(6);
            margin-bottom: 0;
		}

        &--text {
            margin: 15px 0 -15px;

            @include breakpoint('medium') {
                margin: 0 0 -15px;
            }
        }

        figure {
            margin: 0;
        }
    }
}