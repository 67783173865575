.resource-library {
	&__wrapper {
		@include breakpoint('large') {
			display: flex;
			flex-direction: row;
			gap: 60px;
		}
	}

	&__nav {
		padding-right: 3rem;
		border-bottom: 1px solid #ccc;
		margin-bottom: 2rem;

		@include breakpoint('large') {
			border-bottom: none;
			border-right: 1px solid #ccc;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		li {
			margin-bottom: 0.5rem;
		}

		a {
			display: block;
			padding: 0.5rem 0;
		}
	}

	&__content {
		width: 100%;

		> h2 {
			margin: 0 1.5rem 2rem;
		}
	}

	&__header {
		margin-bottom: 5rem;
	}

	&__item {
		display: flex;
		gap: 30px;
		padding-bottom: 2rem;
		margin: 2rem 0;
	}

	&__cards {

	}

	&__card {
		border-bottom: 1px solid #ccc;
		margin-bottom: 3rem;
	}

}
