$colour__primary: #e30613;

$colour__secondary--100: #848484;
$colour__secondary--400: #2b2012;

$colour__body-text: $colour__secondary--400;

$colour__white: #fff;
$colour__black: #000;
$colour__lightgrey: #eee;
$colour__midgrey: #cecece;
$colour__grey--400: #8d8d8d;
$colour__grey: #666;
$colour__grey--nav: #6f6f6f;
$colour__darkgrey: #333;
$colour__error: $colour__primary;

$colour__nav-bg: $colour__grey--nav;
$colour__nav-link: #fff;
$colour__nav-link--hover: $colour__primary;
$colour__error: #f00;

$colour__blue-500: #518EA8;
