.search-form {

	.search__input {
		padding: 0.25rem;
		border: 1px solid #000;
		transition: width 300ms linear;
	}

	&--header {
		display: inline-flex;
		flex-direction: row;
		align-items: center;

		.search__input {
			width: 15rem;
		}
	}

	&--hidden {
		.search__input {
			width: 0;
			border: none;
		}
	}

	@media only screen and (min-width: 560px) {
		&--header {
			.search__input {
				width: 18rem;
			}
		}

		&--hidden {
			.search__input {
				width: 0;
			}
		}
	}
}
